import { RadiographSet } from "library/common/types/patientTypes"

export const updatedRadiographSetPositions = (
  activeRadiographSet: RadiographSet | null
) => {
  // Consider changes to the positions of the radiographs in the set
  const arr1 = activeRadiographSet?.positions || []
  const arr2 = activeRadiographSet?.changes || []
  const map = new Map(arr2.map((item) => [item.resultId, item]))

  return arr1
    .map((item) => {
      if (map.has(item.resultId)) {
        return { ...item, ...map.get(item.resultId) }
      }
      return item
    })
    .sort((a, b) => a.position - b.position)
}
