import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/modal"
import { HelpModalEntry } from "../types/modalTypes"

export enum Modals {
  SELECT_RADIOGRAPH = "SELECT_RADIOGRAPH",
  ROTATE_X_RAY_IMAGE = "ROTATE_X_RAY_IMAGE",
  CHANGE_IMAGE_META = "CHANGE_IMAGE_META",
  RESET_PREDICTIONS = "RESET_PREDICTIONS",
  REANALYSE_ALERT = "REANALYSE_ALERT",
  TOOTH_BASED_PERI_ALERT = "TOOTH_BASED_PERI_ALERT",
  ROTATE_OPTIONS_MODAL = "ROTATE_OPTIONS_MODAL",
  NEW_AI_VERSION_MODAL = "NEW_AI_VERSION_MODAL",
  NEW_USER_ONBOARDING_MODAL = "NEW_USER_ONBOARDING_MODAL",
  NO_SUBSCRIPTION_MODAL = "NO_SUBSCRIPTION_MODAL",
  EXTENDED_REPORT_MODAL = "EXTENDED_REPORT_MODAL",
  BONE_LOSS_ONLY_UPSELL_MODAL = "BONE_LOSS_ONLY_UPSELL_MODAL",
  FAQ_MODAL = "FAQ_MODAL",
  UNSAVED_CHANGES = "UNSAVED_CHANGES",
}

type ModalState = Readonly<{
  openedModal: Modals | null
  currentFaqTab: HelpModalEntry
  newAIVersionModalShownIds: string[]
  navigatingUrl: string | null
}>

const initialState: ModalState = {
  openedModal: null,
  currentFaqTab: HelpModalEntry.LEGEND,
  newAIVersionModalShownIds: [],
  navigatingUrl: null,
}

type ModalActions = ActionType<typeof actions>

export default (state = initialState, action: ModalActions): ModalState => {
  switch (action.type) {
    case getType(actions.openModal):
      return {
        ...state,
        ...(!action.payload
          ? {
              openedModal: null,
              currentFaqTab: HelpModalEntry.LEGEND,
              navigatingUrl: null,
            }
          : { ...action.payload }),
      }

    case getType(actions.closeModal):
      return { ...state, openedModal: null, navigatingUrl: null }
    case getType(actions.setNewAIVersionModalShownIds):
      return {
        ...state,
        newAIVersionModalShownIds: [
          ...state.newAIVersionModalShownIds,
          action.payload,
        ],
      }

    default:
      return state
  }
}
