import { RegulatoryMarket } from "./serverDataTypes"

export enum featuresTypes {
  SET_INITIAL_STATE = "@@FEATURES/SET_INITIAL_STATE",
  UPDATE_FEATURES = "@@FEATURES/UPDATE_FEATURES",
}

export type Features = Readonly<{
  featureNervus: boolean
  featureNerveCanal: boolean
  featureCalculus: boolean
  featureBoneloss: boolean
  featureDrawingMode: boolean
  featureFmx: boolean
  featureImpacted: boolean
  featurePblLandmarks: boolean
  featureCopyToClipboard: boolean
  featureShowSSO: boolean
  featureShowIteroLogin: boolean
  featureCoachMarks: boolean
  featureWhatsNew: boolean
  featureCariesProForPeri: boolean
  featurePerioProForPeri: boolean
  featureDrawingModeInPeri: boolean
  featureHsmInPeri: boolean
  featureShowTimeOut: boolean
  featureCroppedTeeth: boolean
  featureCalculusOnPeri: boolean
  featureShowNotes: boolean
  featurePatientFileConfirmedAnnotations: boolean
  featureShowOrthoWarning: boolean
  featureAlfaDocsIntegration: boolean
  featureRememberHsm: boolean
  featureDynamicPbl: boolean

  regulatoryMarket: RegulatoryMarket
  showInactivePblButton: boolean
  revertVersion: boolean
  showPdfVersion: boolean
  hidePblClassification: boolean
  showFullPblForm: boolean
  showThemeTestButtons: boolean
  showSetupInOnboarding: boolean
  manualVersion: string
  manualLanguages: string[]
  mirthInstaller: string
}>
