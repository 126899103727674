import { AllConfirmedAnnotations } from "./adjustmentTypes"
import { ResultStatus } from "./dataStructureTypes"
import { IMeta, INote, Kind } from "./serverDataTypes"

export enum patientTypes {
  SET_ACTIVE_PATIENT_RESULT = "@@PATIENT/SET_ACTIVE_PATIENT_RESULT",
  GET_PATIENTS_FROM_SERVER = "@@PATIENT/GET_PATIENTS_FROM_SERVER",
  SET_PATIENT_LIST_RESULT = "@@PATIENT/SET_PATIENT_LIST_RESULT",
  REQUEST_PATIENT = "@@PATIENT/REQUEST_PATIENT",
  DELETE_ACTIVE_PATIENT_XRAY = "@@PATIENT/DELETE_ACTIVE_PATIENT_XRAY",
  SET_INITIAL_STATE = "@@PATIENT/SET_INITIAL_STATE",
  SET_FILTER_VALUES = "@@PATIENT/SET_FILTER_VALUES",
  SET_FILTERED_STATUS = "@@PATIENT/SET_FILTERED_STATUS",
  SET_PATIENT_LIST_RESULT_STATUS = "@@PATIENT/SET_PATIENT_LIST_RESULT_STATUS",
  SET_ACTIVE_PATIENT_RESULT_STATUS = "@@PATIENT/SET_ACTIVE_PATIENT_RESULT_STATUS",
  TOGGLE_IS_PATIENT_MATCHING_MODE = "@@PATIENT/TOGGLE_IS_PATIENT_MATCHING_MODE",
  CLEAR_ACTIVE_PATIENT_RESULT = "@@PATIENT/CLEAR_ACTIVE_PATIENT_RESULT",
  OVERWRITE_PATIENT = "@@PATIENT/OVERWRITE_PATIENT",
  GET_EXTERNAL_PATIENT = "@@PATIENT/GET_EXTERNAL_PATIENT",
  SET_PATIENT_MATCH = "@@PATIENT/SET_PATIENT_MATCH",
  LINK_PATIENT = "@@PATIENT/LINK_PATIENT",
  RELINK_PATIENT = "@@PATIENT/RELINK_PATIENT",
  SET_IS_PATIENT_MATCHING_MODE = "@@PATIENT/SET_IS_PATIENT_MATCHING_MODE",
  SET_PATIENT_LIST_SORTING = "@@PATIENT/SET_PATIENT_LIST_SORTING",
  SET_ACTIVE_RADIOGRAPH_SET = "@@PATIENT/SET_ACTIVE_RADIOGRAPH_SET",
  REPLACE_RADIOGRAPH_SET_IMAGE = "@@PATIENT/REPLACE_RADIOGRAPH_SET_IMAGE",
  SET_PATIENT_METADATA = "@@PATIENT/SET_PATIENT_METADATA",
  GET_CONFIRMED_ANNOTATIONS = "@@PATIENT/GET_CONFIRMED_ANNOTATIONS",
  SET_CONFIRMED_ANNOTATIONS = "@@PATIENT/SET_CONFIRMED_ANNOTATIONS",
  SET_ACTIVE_PATIENT_LIST_ITEM = "@@PATIENT/SET_ACTIVE_PATIENT_LIST_ITEM",
  TOGGLE_ACCORDION = "@@PATIENT/TOGGLE_ACCORDION",
  SET_ACTIVE_SET_IMAGE = "@@PATIENT/SET_ACTIVE_SET_IMAGE",
  SET_ACTIVE_SET_IMAGE_ID = "@@PATIENT/SET_ACTIVE_SET_IMAGE_ID",
  SET_IS_EDIT_SET_ACTIVE = "@@PATIENT/SET_IS_EDIT_SET_ACTIVE",
  UPDATE_RADIOGRAPH_POSITION = "@@PATIENT/UPDATE_RADIOGRAPH_POSITION",
  REMOVE_RADIOGRAPH_FROM_SET = "@@PATIENT/REMOVE_RADIOGRAPH_FROM_SET",
}

export interface PatientList {
  id: string
  patientID: string | null
  patientUuid: string | null
  patientName: string | null
  dateOfBirth: string | null
  imageDate: string
  modalities: Record<string, number>
  statusTag?: number
  reportDate?: string
  comments?: string[]
  viewed: boolean
  status?: Status
  links: number
  xrays?: number
}

export interface PatientListResult {
  resultStatus: ResultStatus
  patientList: PatientList[]
}

export interface Patient {
  patientUuid: string | null
  patientID: string | null
  patientName: string | null
  dateOfBirth: string | null
}

export interface ConfirmedAnnotation {
  type: AllConfirmedAnnotations
  teeth: number
}

export interface ConfirmedAnnotations {
  annotations?: ConfirmedAnnotation[]
  boneloss?: number
  status: ResultStatus
}

export interface IResultStatus {
  id: string
  status: ResultStatus
}
export interface IActivePatientListItem {
  id: string
  isRadiographSet: boolean
}
export interface ActivePatientImages {
  resultId: string | null
  report_submitted: string
  viewed: boolean
  statusTag?: number
  generalComment: string
  addedComments: { comment: string; toothName: number }[]
  imageMetadata: IMeta
  imageDate: string
  status: Status
  notes?: INote[]
  confirmedAnnotations?: ConfirmedAnnotations
}

export interface ActivePatientResult {
  resultStatus: ResultStatus
  images: ActivePatientImages[] | null
  patient: Patient | null
  links: PatientMatch[]
  radiographSets: RadiographSet[]
  activeRadiographSet: RadiographSet | null
  activePatientListItem: IActivePatientListItem | null
  isAccordionOpen: boolean
  isEditSetActive: boolean
}

export interface PatientListServerResult {
  data: PatientList[]
}

export interface RadiographSet {
  id: string
  created: string
  updated: string
  radiographs: string[]
  activeSetImage: SetPositioning | null
  template: FmxTemplate
  positions: SetPositioning[] | null
  changes: SetPositioning[] | null
}

export interface UpdateSet {
  id: string
  set: CreateRadiographSet
}

export type CreateRadiographSet = {
  radiographs: string[]
  template: FmxTemplate
  positions: SetPositioning[]
  changes: SetPositioning[] | null
}

export interface ActivePatientServerResult {
  data: {
    images: ActivePatientImages[] | null
    patient: Patient
    links: PatientMatch[]
    radiographSets?: RadiographSet[]
  }
}

export interface IPagination {
  page: number
  perPage: number
}

export interface FilterState {
  section: string
  selected: string[]
  match: (patient: PatientList, selected: string[]) => boolean
}

export interface PatientMatch {
  chartId: string | null
  dateOfBirth: string | null
  externalId: string
  firstName: string | null
  lastName: string | null
  link: string | null
  status?: string
}

export interface PatientMetaRequest {
  links: PatientMatch[]
  patient: Patient
}

export interface MatchingPatient {
  id: string
  matchingPatientId: string | null
  linkedMeta?: PatientMatch[]
}

export interface PatientListSorting {
  key: string
  sortDirection: string
}

export enum Status {
  all = "all",
  new = "new",
  confirmed = "confirmed",
  viewed = "viewed",
  uploaded = "uploaded",
}

export interface SubtypeCounts {
  subtype: string
  count: number
}

const rotations = [0, 90, 180, 270] as const
type Rotation = (typeof rotations)[number]

interface IImageData {
  id: number
  kind: Kind
  src: string
  flip?: boolean
  rotate?: Rotation
  thumbnailImageWidth: number
  thumbnailImageHeight: number
  dateOfRadiograph: Date
  dateOfReport: Date
  notes: string
  imageId: string
}

export interface IActiveSetData {
  radiographDates: string[]
  statuses: Status[]
  created: string
  id: string
  radiographs: string[]
  updated: string
  activeSetImage: SetPositioning | null
}

export interface IPatientFileItem {
  imageDate: Date
  imageData: IImageData[]
}

export type ModalityFilter = Kind | "ALL" | "SET"

export type SortedItems = {
  date: string
  id: string | null
  isRadiographSet: boolean
}

export type SetPositioning = {
  resultId: string
  position: number
}

export type UpdatedSetPosition = {
  currentRadiograph: SetPositioning
  newPosition: number
}

export enum FmxTemplate {
  fmx14 = "fmx-14",
}
