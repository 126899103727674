import {
  fork,
  takeLatest,
  call,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects"

import * as imageActions from "library/common/actions/image"
import * as entitiesActions from "library/common/actions/entities"
import * as teethActions from "library/common/actions/teeth"
import * as filtersActions from "library/common/actions/filters"
import * as imageControlsActions from "library/common/actions/imageControls"
import * as adjustmentsActions from "library/common/actions/adjustments"
import * as drawingActions from "library/common/actions/drawing"
import * as webSocketActions from "library/common/actions/webSocket"
import * as serverDataActions from "library/common/actions/serverData"
import {
  setServerError,
  setServerErrorMessage,
  setUserInfo,
  Theme,
} from "library/common/actions/user"
import * as patientActions from "library/common/actions/patient"

import * as imageAPIs from "library/services/imageApis"
import * as routeSelectors from "library/common/selectors/routes"
import {
  imageTypes,
  PdfReportData,
  PdfData,
} from "library/common/types/imageTypes"
import {
  BoneLossFormFull,
  IMeta,
  INote,
  Kind,
} from "library/common/types/serverDataTypes"
import {
  Detection,
  UserChange,
  Boneloss,
  ToothSegment,
  HistoricalResult,
  NerveCanal,
  ICroppedTeeth,
  ResultStatus,
} from "library/common/types/dataStructureTypes"
import {
  AnnotationName,
  AnnotationOnTooth,
  RestorationSubtype,
} from "../types/adjustmentTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"
import { Comment } from "../types/serverDataTypes"
import { getLang } from "library/common/selectors/i18n"
import {
  requestSendChanges,
  requestSendChangesComplete,
  setDataIsChanged,
} from "../actions/saving"
import { history } from "core/store/configureStore"
import {
  getShowImmediately,
  getBonelossPro,
  getImageResultStatus,
  getShownRadiographAnnotations,
  getIsReportSentToAlfaDocs,
} from "../selectors/image"
import { SaveComplete, SavingType } from "../types/savingTypes"
import { closeModal, openModal } from "../actions/modal"
import {
  getBoneLossOnly,
  getContextQueryParams,
  getImpersonate,
  getModalities,
  getShowToothBasedPeri,
  getTheme,
} from "../selectors/user"
import {
  getAllUserChanges,
  getIsImageHorizontallyFlipped,
  getIsOutdatedAnalysis,
  getKind,
  getPatientUuid,
  getToothBasedPeri,
} from "../selectors/serverData"
import { getEntities, getImageId } from "../selectors/entities"
import { Entities } from "../types/entitiesTypes"
import {
  SHOW_NEW_TOOTH_MAP,
  TOOTH_MAP_POSITION_ASPECT_RATIO,
} from "library/utilities/constants"
import { Modals } from "../reducers/modalsReducer"
import { setLegend } from "../actions/legend"
import localStorage from "library/utilities/localStorage"
import {
  getNewAIVersionModalShownIds,
  getOpenedModal,
} from "../selectors/modals"
import { getPatientFileBreadcrumb } from "../selectors/breadcrumbs"
import {
  setImageIDBreadcrumb,
  setPatientFileBreadcrumb,
  setReportBreadcrumb,
} from "../actions/breadcrumbs"
import {
  isDashboard,
  isPatientFile,
  patientFileUrl,
} from "library/utilities/urls"
import {
  getActivePatientResult,
  getActivePatientUuid,
} from "../selectors/patient"
import { ContextQuery } from "../types/userTypes"
import { getTimezone } from "../../utilities/date"
import { ActivePatientResult } from "../types/patientTypes"
import { setOpenToast } from "../actions/toast"
import { ToastType } from "../types/toast"
import i18next from "i18next"
import {
  getFeatureDynamicPbl,
  getFeatureNerveCanal,
  getRevertVersion,
  getShowPdfVersion,
} from "../selectors/features"
import { setShowDynamicPbl } from "library/common/actions/imageControls"

export interface IAnalysisResult {
  data: IAnalysisData
}

interface IRotationData {
  incorrectOrientation: boolean
  rotationConfidence: number
}

interface IAnalysisData {
  id?: string
  apical?: Detection[]
  boneLoss?: Boneloss
  caries?: Detection[]
  restorations?: Detection[]
  changes?: UserChange[]
  additions?: AnnotationOnTooth[]
  addedComments?: Comment[]
  generalComment?: string
  addedTeeth?: Tooth[]
  removedTeeth?: Tooth[]
  movedTeeth?: Record<string, number>
  teeth?: Tooth[]
  meta?: IMeta
  cariesPro?: boolean
  bonelossPro?: boolean
  status: string
  message?: string
  forms?: { boneLoss: BoneLossFormFull }
  calculus?: Detection[]
  nervus?: Detection[]
  segments?: ToothSegment[]
  impacted?: Detection[]
  historicalResults?: HistoricalResult[]
  rotation?: IRotationData
  nerveCanal?: NerveCanal[]
  viewed: boolean
  croppedTeeth?: ICroppedTeeth
  notes?: INote[]
}

function* cleanCachedImageSaga(skip: any) {
  yield put(entitiesActions.setInitialState())
  yield put(teethActions.setInitialState())
  if (!skip.image) yield put(imageActions.setInitialState())
  yield put(imageActions.setAsNotProcessed())
  yield put(filtersActions.setInitialState())
  yield put(imageControlsActions.setInitialState())
  yield put(adjustmentsActions.setInitialState())
  yield put(serverDataActions.setInitialState())
  yield put(drawingActions.setInitialState())
  yield put(patientActions.setInitialState())
}

// reject boneLoss that has neither annotations nor pb_roi
const cleanBoneLoss = (boneLoss: any) =>
  boneLoss && (boneLoss.pb_roi || boneLoss.annotations)
    ? { annotations: [], ...boneLoss }
    : undefined

// reject nervus that doesn't fit our expected format
const cleanNervus = (nervus: any) =>
  nervus && nervus[0]?.annotations ? [] : nervus

export function* updateImageDataSaga(data: IAnalysisData) {
  const modalities: string = yield select(getModalities)
  const patientUuid: string = yield select(getPatientUuid)
  const activePatientResult: ActivePatientResult = yield select(
    getActivePatientResult
  )
  const boneLossOnly: boolean = yield select(getBoneLossOnly)
  const isIntegrated: boolean = yield select(routeSelectors.getIsIntegrated)

  // Only show detections when kind is supported
  if (!data.meta || modalities.includes(data.meta.kind)) {
    yield put(
      entitiesActions.saveAnnotations({
        apical: data.apical || [],
        boneloss: cleanBoneLoss(data.boneLoss),
        caries: data.caries || [],
        restorations: data.restorations || [],
        detectedTeeth: data.teeth || [],
        calculus: data.calculus || [],
        nervus: cleanNervus(data.nervus) || [],
        segments: data.segments || [],
        impacted: data.impacted || [],
        historicalResults: data.historicalResults || [],
        nerveCanal: data.nerveCanal || [],
        croppedTeeth: data.croppedTeeth || undefined,
      })
    )
  }
  yield put(entitiesActions.setImageId(data.id || ""))
  yield put(entitiesActions.setViewed(data.viewed || false))
  yield put(serverDataActions.addUserAdditions(data.additions || []))
  yield put(serverDataActions.addUserChanges(data.changes || []))
  yield put(serverDataActions.userAddAddedTeeth(data.addedTeeth || []))
  yield put(serverDataActions.userAddDeletedTeeth(data.removedTeeth || []))
  yield put(serverDataActions.addAddedComments(data.addedComments || []))
  yield put(serverDataActions.setGeneralComment(data.generalComment || ""))
  yield put(serverDataActions.setNotes(data.notes || []))
  yield put(serverDataActions.setMovedTeeth(data.movedTeeth || {}))
  if (data.forms) {
    yield put(serverDataActions.saveBoneLossForm(data.forms.boneLoss))
  }
  if (data.meta) {
    const meta = {
      ...data.meta,
      isImageHorizontallyFlipped: !!data.meta.isImageHorizontallyFlipped,
      angleImageRotation: data.meta.angleImageRotation || 0,
      ...((data.meta.angleImageRotation === 90 ||
        data.meta.angleImageRotation === 270) && {
        imageWidth: data.meta.imageHeight,
        imageHeight: data.meta.imageWidth,
      }),
      isOwner: data.meta.isOwner ?? true,
      outdatedAnalysis: data.meta.outdatedAnalysis && data.status === "done",
    }
    const patientMeta = {
      patientID: data.meta.patientID,
      patientName: data.meta.patientName,
      dateOfBirth: data.meta.dateOfBirth,
      patientUuid: data.meta.patientUuid,
    }

    yield put(patientActions.setPatientMetadata(patientMeta))
    yield put(serverDataActions.saveImageMeta(meta))
    const perioOnly = boneLossOnly && data.meta.kind !== Kind.Opg
    if (
      (isIntegrated && perioOnly) ||
      (perioOnly && isDashboard(history.location))
    ) {
      yield put(openModal({ openedModal: Modals.BONE_LOSS_ONLY_UPSELL_MODAL }))
    }
    if (patientMeta.patientUuid !== activePatientResult.patient?.patientUuid) {
      yield put(
        patientActions.setActivePatientResult({
          ...activePatientResult,
          patient: patientMeta,
        })
      )
    }

    if (data.meta.patientUuid !== patientUuid) {
      yield put(setPatientFileBreadcrumb(patientFileUrl(data.meta.patientUuid)))
    }
    if (meta.kind === Kind.Other) {
      // don't show detections for OTHER
      yield put(imageActions.setShowImmediately(false))
    }
  }
  const { cariesPro, bonelossPro, status, message } = data
  const featureDynamicPbl: boolean = yield select(getFeatureDynamicPbl)
  const theme: Theme = yield select(getTheme)
  yield put(
    setUserInfo({
      bonelossPro: theme === Theme.carestream ? false : bonelossPro,
      cariesPro,
    })
  )
  yield put(setShowDynamicPbl(featureDynamicPbl))
  const isLegendOpen = localStorage.getItem("is_legend_open")
  // Before the value is set, show it as open
  if (isLegendOpen === null) {
    yield put(setLegend(true))
    localStorage.setItem("is_legend_open", true)
  } else {
    yield put(setLegend(isLegendOpen))
  }
  const isReportPage = history.location.pathname.includes("report")

  const impersonate: string = yield select(getImpersonate)

  if (
    data.rotation?.incorrectOrientation &&
    !isReportPage &&
    !impersonate &&
    (isDashboard(history.location) || isIntegrated)
  ) {
    yield put(
      openModal({ openedModal: Modals.ROTATE_OPTIONS_MODAL, navigatingUrl: "" })
    )
  }

  const isOutdatedAnalysis: boolean = yield select(getIsOutdatedAnalysis)
  const openedModal: Modals = yield select(getOpenedModal)
  const newAIVersionModalShownIds: string[] = yield select(
    getNewAIVersionModalShownIds
  )
  const imageId: string = yield select(routeSelectors.getRouteImageId)
  const featureNerveCanal: boolean = yield select(getFeatureNerveCanal)

  if (
    // Old AI version
    !impersonate &&
    ((isOutdatedAnalysis &&
      openedModal !== Modals.TOOTH_BASED_PERI_ALERT &&
      !isReportPage &&
      !newAIVersionModalShownIds.includes(imageId)) ||
      // Has new nerveCanal available
      (featureNerveCanal && data.nervus && !data.nerveCanal))
  ) {
    yield put(openModal({ openedModal: Modals.NEW_AI_VERSION_MODAL }))
  }

  // Set largerAspectRatio ratio here for initial tooth map position to prevent jumping around
  yield put(
    imageControlsActions.setIsLargerAspectRatioScreen(
      window.innerWidth / window.innerHeight > TOOTH_MAP_POSITION_ASPECT_RATIO
    )
  )

  const inferenceStatus = {
    status,
    message,
  }
  yield put(serverDataActions.setInferenceStatus(inferenceStatus))

  if (status === "done" || status === "error") {
    yield put(imageActions.loadAnnotationsSuccess())
  } else if (data.id) {
    yield put(webSocketActions.connect(data.id))
  }

  if (isDashboard(history.location) || isIntegrated) {
    yield put(setImageIDBreadcrumb(`/dashboard/${imageId}`))
  }

  /*
  Only set patientFileBreadcrumb if it's not set yet. i.e. after refresh
  */
  const patientFileBreadcrumb: string = yield select(getPatientFileBreadcrumb)
  if (patientFileBreadcrumb) return

  yield put(setPatientFileBreadcrumb(patientFileUrl(patientUuid)))
}

function* loadAnnotationsSaga() {
  try {
    /*
      When loading the radiograph from the patient file, we use the image id that is stored in the server
      data linked to the selected image. When we are on the Dashboard, we get the image id from the url.
    */
    const id: string = isPatientFile(history.location)
      ? yield select(getImageId)
      : yield select(routeSelectors.getRouteImageId)
    const shownRadiographAnnotations: string = yield select(
      getShownRadiographAnnotations
    )
    const params: ContextQuery = yield select(getContextQueryParams)
    const revertVersion: boolean = yield select(getRevertVersion)
    if (id) {
      const { data }: IAnalysisResult = yield call(
        imageAPIs.requestImageAnalysis,
        id,
        { ...params, showHistory: revertVersion }
      )
      yield call(updateImageDataSaga, data)
      const showImmediately: boolean = yield select(getShowImmediately)
      const isReportSentToAlfaDocs: boolean = yield select(
        getIsReportSentToAlfaDocs
      )
      if (
        showImmediately &&
        data.status !== "error" &&
        data.meta?.kind !== Kind.Other
      ) {
        yield put(imageActions.setShownRadiographAnnotations(id))
      }

      /*
        If the image id is different from the shownRadiographAnnotations,
        reset annotationsShown and the report breadcrumb
      */
      if (!showImmediately && shownRadiographAnnotations !== id) {
        yield put(imageActions.setShownRadiographAnnotations(""))
        yield put(setReportBreadcrumb(""))
        if (isReportSentToAlfaDocs) {
          yield put(imageActions.setIsReportSentToAlfaDocs(false))
        }
      }
    } else {
      yield put(setServerError("unavailable"))
      yield put(setServerErrorMessage("invalid uuid"))
    }
  } catch (error) {
    yield put(
      setServerErrorMessage(error.response?.data?.error || "Unexpected Error")
    )
  }

  const entities: Entities = yield select(getEntities)
  const userChanges: UserChange[] = yield select(getAllUserChanges)
  const changedIds = userChanges.flatMap((u) => u.annotationId)

  const keys = [
    AnnotationName.apical,
    AnnotationName.calculus,
    AnnotationName.caries,
    AnnotationName.nervus,
    AnnotationName.restorations,
    AnnotationName.impacted,
  ]

  // Only work with applicable entities
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const filteredEntities = keys.flatMap((k) => entities[k] || [])
  const changedTeeth = filteredEntities.flatMap(
    (f) => (changedIds.includes(f.id) && f.toothName) || []
  )

  const uniqueChangedTeeth = new Set(changedTeeth)

  // Extract tooth names where a certain restoration appears
  const getAffectedTeeth = (subtype: RestorationSubtype) =>
    entities.restorations
      .filter((e) => e.subtype === subtype)
      .map((e) => e.toothName)

  const filteredBridges = getAffectedTeeth(RestorationSubtype.bridges)
  const filteredImplants = getAffectedTeeth(RestorationSubtype.implants)

  const rejectedIds = filteredEntities
    .filter(
      (f) =>
        !uniqueChangedTeeth.has(f.toothName) &&
        ((f.subtype !== RestorationSubtype.bridges &&
          filteredBridges.includes(f.toothName)) ||
          (f.subtype !== RestorationSubtype.bridges &&
            f.subtype !== RestorationSubtype.crowns &&
            f.subtype !== RestorationSubtype.implants &&
            filteredImplants.includes(f.toothName)))
    )
    .map((entity) => entity.id)

  yield put(
    serverDataActions.addUserChanges(
      rejectedIds.map((detectionId) => ({
        action: "rejected",
        annotationId: detectionId,
      }))
    )
  )
}

// Reset the local data and send the reanalysis request to the server
export function* reanalyseImage(payload: imageAPIs.ReanalyzeRequest) {
  const params: ContextQuery = yield select(getContextQueryParams)
  yield call(cleanCachedImageSaga, { image: true })
  yield put(imageActions.setImageResultStatus(ResultStatus.loading))
  try {
    const { data }: IAnalysisResult = yield call(
      imageAPIs.requestReanalyze,
      payload,
      params
    )
    yield fork(updateImageDataSaga, data)
    yield put(imageActions.setImageResultStatus(ResultStatus.none))
  } catch (error) {
    console.error(error)
    yield put(imageActions.setImageResultStatus(ResultStatus.error))
  }
}

// Reset the analysis
export function* reanalyseImageSaga() {
  const id: string = yield select(getImageId)

  yield put(requestSendChanges({ resultId: id, savingChanges: SavingType.ALL }))
  const { payload }: { payload: SaveComplete } = yield take(
    requestSendChangesComplete
  )
  if (payload.success) {
    yield call(reanalyseImage, { id })
    yield put(setImageIDBreadcrumb(""))
  }

  const imageResultStatus: ResultStatus = yield select(getImageResultStatus)
  if (imageResultStatus !== ResultStatus.loading) {
    yield put(closeModal())
  }
}

export function* refreshPatientFile() {
  // Refresh patient file with new values when rotation or changing of image type happens in patient file
  const patientUuid: string = yield select(getPatientUuid)

  if (isPatientFile(history.location)) {
    yield put(patientActions.requestPatient(patientUuid || "unassigned"))
    yield put(setImageIDBreadcrumb(""))
  }
}

// Reset the analysis and rotate 180 degrees
function* rotateImageSaga({
  payload: { rotate, isFlipped, imageId },
}: ReturnType<typeof imageActions.rotateImage>) {
  const isImageHorizontallyFlipped: boolean = yield select(
    getIsImageHorizontallyFlipped
  )
  const imageResultStatus: ResultStatus = yield select(getImageResultStatus)
  if (isFlipped !== isImageHorizontallyFlipped) {
    yield put(serverDataActions.flipImage())
  }
  if (rotate !== 0 || isFlipped !== isImageHorizontallyFlipped) {
    yield call(reanalyseImage, { id: imageId, rotate, isFlipped })
    yield call(refreshPatientFile)
  }
  if (imageResultStatus !== ResultStatus.loading) {
    yield put(closeModal())
  }
}

// Reset the analysis and change the image kind
function* changeRadiographTypeSaga({
  payload: { kind, id },
}: ReturnType<typeof imageActions.changeRadiographType>) {
  const imageResultStatus: ResultStatus = yield select(getImageResultStatus)

  yield call(reanalyseImage, { id, kind })
  yield call(refreshPatientFile)
  if (imageResultStatus !== ResultStatus.loading) {
    yield put(closeModal())
  }
}

function* loadImageSaga() {
  yield call(cleanCachedImageSaga, {})
  yield put(imageActions.loadImageSuccess())
}

export function* resetOpenDataMsSaga() {
  yield put(imageActions.updateOpenDateMs())
}

export function* loadPdfReportSaga({
  payload,
}: ReturnType<typeof imageActions.loadPdfReport>) {
  const lang: string = yield select(getLang)
  const activePatientUuid: string | null = yield select(getActivePatientUuid)
  const params: ContextQuery = yield select(getContextQueryParams)
  const showPdfVersion: boolean = yield select(getShowPdfVersion)
  try {
    const pdfData: PdfData = yield call(
      imageAPIs.requestPdfReport,
      payload.id,
      {
        lang,
        theme: "xrayinsights",
        patientUuid: activePatientUuid,
        showVersion: showPdfVersion,
        toothmapV2: SHOW_NEW_TOOTH_MAP,
        ...(payload.isBoneLossPdf
          ? { boneLossDetections: "0.5", reports: "bone-loss" }
          : { detections: "0.5" }),
        tz: getTimezone(),
        ...params,
      }
    )
    const data = pdfData.data
    if (!data.pdf) {
      throw Error("PDF Report could not be loaded, empty pdf key in response.")
    }
    const pdfDataPayload: PdfReportData = {
      pdfReportData: data.pdf,
      textAnnotations: data.text,
    }
    yield put(imageActions.loadPdfReportSuccess(pdfDataPayload))
  } catch (error) {
    console.error(error)
  }
}

export function* openPdfReportSaga() {
  const id: string = yield select(getImageId)
  yield put(setDataIsChanged(false)) // Prevent save alert to pop up.
  yield put(requestSendChanges({ resultId: id, savingChanges: SavingType.ALL }))
  const { payload }: { payload: SaveComplete } = yield take(
    requestSendChangesComplete
  )
  const boneLossPro: boolean | null = yield select(getBonelossPro)
  const isIntegrated: boolean = yield select(routeSelectors.getIsIntegrated)
  if (payload.success) {
    const prefix = isIntegrated ? "integrated-" : ""
    const suffix = boneLossPro ? "-bone-loss" : ""
    history.push(`/${prefix}report${suffix}/${payload.id}`)
  }
}

export function* revertImageSaga({
  payload: id,
}: ReturnType<typeof imageActions.revertImage>) {
  const resultId: string = yield select(routeSelectors.getRouteImageId)
  const params: ContextQuery = yield select(getContextQueryParams)

  try {
    yield call(imageAPIs.revertImage, resultId, id, params)
    yield call(cleanCachedImageSaga, {})
    yield put(imageActions.loadImageSuccess())
  } catch (error) {
    console.error(error)
  }
}

export function* loadAnnotationsSuccess() {
  const toothBasedPeri: boolean = yield select(getToothBasedPeri)
  const showToothBasedPeri: boolean = yield select(getShowToothBasedPeri)
  const kind: Kind = yield select(getKind)

  if (kind === Kind.Peri && !toothBasedPeri && showToothBasedPeri) {
    yield put(openModal({ openedModal: Modals.TOOTH_BASED_PERI_ALERT }))
  }
}

export function* sendReportSaga({
  payload: { pdfReport, id },
}: ReturnType<typeof imageActions.sendReport>) {
  yield put(imageActions.setImageResultStatus(ResultStatus.loading))
  try {
    yield call(imageAPIs.requestSendPdfReport, id, pdfReport)
    yield put(imageActions.setIsReportSentToAlfaDocs(true))
    yield put(
      setOpenToast({
        type: ToastType.alfaDocsReportSendSuccess,
        message: i18next.t("app.toast.successful_send_pdf_report_alfadocs"),
        notificationType: "success",
      })
    )
    yield put(imageActions.setImageResultStatus(ResultStatus.none))
  } catch (error) {
    yield put(
      setOpenToast({
        type: ToastType.alfaDocsReportSendFailure,
        message: i18next.t("app.toast.unsuccessful_send_pdf_report_alfadocs"),
        notificationType: "error",
      })
    )
    yield put(imageActions.setImageResultStatus(ResultStatus.error))
  }
}

export default function* entitiesSaga() {
  yield takeLatest(imageTypes.LOAD_IMAGE, loadImageSaga)
  yield takeLatest(imageTypes.ROTATE_IMAGE, rotateImageSaga)
  yield takeEvery(
    [imageTypes.IMAGE_PROCESSING_COMPLETE, imageTypes.LOAD_IMAGE_SUCCESS],
    loadAnnotationsSaga
  )
  yield takeLatest(imageTypes.CHANGE_RADIOGRAPH_TYPE, changeRadiographTypeSaga)
  yield takeLatest(imageTypes.REVERT_IMAGE, revertImageSaga)
  yield takeLatest(
    imageTypes.SET_SHOWN_RADIOGRAPH_ANNOTATIONS,
    resetOpenDataMsSaga
  )
  yield takeLatest(imageTypes.LOAD_PDF_REPORT, loadPdfReportSaga)
  yield takeLatest(imageTypes.OPEN_PDF_REPORT, openPdfReportSaga)
  yield takeEvery(imageTypes.REANALYZE_IMAGE, reanalyseImageSaga)
  yield takeEvery(imageTypes.LOAD_ANNOTATIONS_SUCCESS, loadAnnotationsSuccess)
  yield takeEvery(imageTypes.SEND_REPORT, sendReportSaga)
}
